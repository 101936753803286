import React from "react"
import Layout from "../../components/layout/Layout.js"
import { BlogRow, Row } from "../../components/row/Row.js"
import {
  BlogTitle,
  SubHeading,
} from "../../components/title/Title.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/campaign-setup.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { Contact, ButtonContainer} from '../../components/button/Button.js'
import { TextMedium } from "../../components/text/Text.js"


function ContentCampaignSetup({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="Content Campaign Setup - Template" 
       description="This template includes target audiences with pain points, reasons to buy and objections, along with a 12-week campaign flow."
       image={img1}
       featuredImage={img1} />
      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/content-campaign-setup-template/">Content Campaign Setup - Template</a>
            </li>
          </Breadcrumb>
        </Row>
          <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="Content Campaign Setup - Template"/>
            <SubHeading subheading="This template includes target audiences with pain points, reasons to buy and objections, along with a 12-week campaign flow." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/content-campaign-setup-b2b" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="Content campign setup" />
          </Column50>
        </Row>
      </AboveFold>


      <Section isCentered>
        <BlogRow isCentered>
          <TextMedium textMedium="We’ve used this template to develop and implement content campaigns for SaaS companies in various niches."/> 
          <GumroadForm 
                formName="Content campaign setup"
                redirectPage="https://socontent.gumroad.com/l/content-campaign-setup-b2b"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/content-campaign-setup-b2b"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
            />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default ContentCampaignSetup
